import { isProductionEnvironment } from './env-utils'

export function getStripePaymentIntentUrl(stripePaymentIntentId: string): string {
  return `${getStripeBaseUrl()}/payments/${stripePaymentIntentId}`
}

export function getStripeCustomerUrl(stripeCustomerIntentId: string): string {
  return `${getStripeBaseUrl()}/customers/${stripeCustomerIntentId}`
}

function getStripeBaseUrl() {
  return isProductionEnvironment() ? 'https://dashboard.stripe.com' : 'https://dashboard.stripe.com/test'
}

export function getGocardlessPaymentIntentUrl(id: string): string {
  return `${getGocardlessBaseUrl()}/payments/${id}`
}

export function getGocardlessCustomerUrl(id: string): string {
  return `${getGocardlessBaseUrl()}/customers/${id}`
}

function getGocardlessBaseUrl() {
  return isProductionEnvironment() ? 'https://manage.gocardless.com' : 'https://manage-sandbox.gocardless.com'
}
